import { Component, OnInit, Inject, NgZone, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/ApiService.class';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { AppConfig } from '../../app.config';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { Computer, XmlModel } from "../../classes/classes";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxCsvParser, NgxCSVParserError, NgxCsvParserModule } from 'ngx-csv-parser';
import { NavbarComponent } from '../../nav/navbar/navbar.component';

import { Html5Qrcode } from "html5-qrcode";


//import { AppConfig } from '../../app.config';
//import { DatePipe } from '@angular/common';
//import { formatDate } from '@angular/common';
//import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';


@Component({
	selector: 'app-assetcreate',
	templateUrl: './assetcreate.component.html',
	providers: [ApiService],
	styleUrls: ['./assetcreate.component.css']
})
export class AssetCreateComponent implements OnInit {

	@ViewChild('serial', { static: false }) serial;

	csvRecords: any[] = [];
	header = true;

	bulkAssets: any;

	@ViewChild('files', { static: false }) fileImportInput: any;
	
	serialRef: any;

	biosserialref: any;

	emptyPost: boolean = true;

	postedValues: boolean = false;

	xmlModel: XmlModel = new XmlModel();

	assets: any;
	biosSerial: string;
	currentCustomer: string;
	manufacturer: string;
	model: string;
	sitename: string;
	searching = false;
	searchFailed = false;
	enteredField: any;
	enteredString: any;
	currentcomputer: any = null;

	serialInput: any;
	manufacturerInput: any;
	modelInput: any;

	// Camera related variables
	cameraId;
	scanning: boolean;
	html5QrCode;
	availableCameras;
	selectedCamera = null;
	scanField: string;
	cameraChosen: boolean = false;
	cameraRunning: boolean = false;
	lookup;

	validateSerialError = false;
	validateManufacturerError = false;
	validateModelError = false;

	singleSelect: any = true;
	bulkSelect: any = false;

	ua = navigator.userAgent;
	deviceType: string = "";

	refDetect: ChangeDetectorRef;

	_http: HttpClient;
	_baseUrl: string;

	ngOnInit() {
		this.isMobile();
		this.getCameras();
	}

	constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private apiService: ApiService, private ngxCsvParser: NgxCsvParser, private zone: NgZone, ref: ChangeDetectorRef) {
		this._http = http;
		this._baseUrl = baseUrl;
		//this.currentcomputer = new Computer();
		//this.refDetect = ref;
		/*
		window['barcodeValidate'] = {
			zone: this.zone,
			componentFn: (value: string) => this.getAsset(value),
			component: this,
		};*/
		
		//this.lookup.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
	}

	isMobile() {
		//let check = false;
		//(function (a) {
		//	if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
		//		check = true;
		//})(navigator.userAgent || navigator.vendor || window.opera);
		//return check;


		if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(this.ua)) {
			return "tablet";
		}
		if (
			/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
				this.ua
			)
		) {
			this.deviceType = "mobile";
		}
		this.deviceType = "desktop";
	};

	getCameras() {
		Html5Qrcode.getCameras().then(devices => {
			/**
			 * devices would be an array of objects of type:
			 * { id: "id", label: "label" }
			 */

			if (devices && devices.length) {
				if (devices.length < 2) {
					this.cameraId = devices[0].id;
				}
				this.availableCameras = devices;
				//console.log("this.availableCameras: ", this.availableCameras);
				// .. use this to start scanning.
				//devices.forEach(dev => { console.log("devices[index]: ", dev, " devices[index].id: ", dev.id) });
			}
		}).catch(err => {
			// handle err
		});
	}

	prepareScanning(field: string) {
		this.scanning = true;
		this.scanField = field;
		if (this.availableCameras && this.availableCameras.length < 2) {
			this.cameraChosen = true;
			this.startScanning();
		}
		else if (this.html5QrCode) {
			this.startScanning();
		}
	}

	cameraSelected(camera: any) {
		this.cameraChosen = true;
		try {
			if (this.availableCameras.length > 1 && this.html5QrCode.isScanning === true) {
				this.swapCamera();
				this.cameraId = camera;
				this.startScanning();
			}
			else {
				this.cameraId = camera;
				this.startScanning();
			}
		}
		catch {
			this.cameraId = camera;
			this.startScanning();
		}

		//console.log("this.cameraChosen: ", this.cameraChosen);
		//console.log("this.cameraRunning: ", this.cameraRunning);
	}

	startScanning() {
		this.cameraRunning = true;
		this.html5QrCode = new Html5Qrcode(/* element id */ "reader");
		//this.lookup = document.getElementById("lookup");
		this.serialInput = document.getElementById("serial");
		this.manufacturerInput = document.getElementById("manufacturer");
		this.modelInput = document.getElementById("model");
		const config = {
			fps: 10,
			qrbox: { width: 250, height: 250 },
			rememberLastUsedCamera: true
			// Only support camera scan type.
		};
		// If you want to prefer back camera

		const qrCodeSuccessCallback = (decodedText, decodedResult) => {
			/* handle success */
			//console.log(`Code matched = ${decodedText}`, decodedResult);
			switch (this.scanField) {
				case "serial":
					this.scanning = false;
					this.serialInput.value = decodedText;
					this.serialInput.dispatchEvent(new Event('input'));
					this.serialInput.focus();
					this.html5QrCode.stop().then((ignore) => {
						// QR Code scanning is stopped.
					}).catch((err) => {
						// Stop failed, handle it.
					});
					break;
				case "manufacturer":
					this.scanning = false;
					this.manufacturerInput.value = decodedText;
					this.manufacturerInput.dispatchEvent(new Event('input'));
					this.manufacturerInput.focus();
					this.html5QrCode.stop().then((ignore) => {
						// QR Code scanning is stopped.
					}).catch((err) => {
						// Stop failed, handle it.
					});
					break;
				case "model":
					this.scanning = false;
					this.modelInput.value = decodedText;
					this.modelInput.dispatchEvent(new Event('input'));
					this.modelInput.focus();
					this.html5QrCode.stop().then((ignore) => {
						// QR Code scanning is stopped.
					}).catch((err) => {
						// Stop failed, handle it.
					});
					break;
				default:
					break;
			}
		
		}

		if (this.deviceType == "mobile") {
			this.html5QrCode.start({ facingMode: "environment" });
		}
		else if (this.deviceType == "desktop") {
			this.html5QrCode.start({ deviceId: { exact: this.cameraId } }, config, qrCodeSuccessCallback);
		}

	}

	swapCamera() {
		try {
			if (this.html5QrCode.isScanning === true) {
				this.cameraRunning = false;
				this.html5QrCode.stop().then((ignore) => {
					// QR Code scanning is stopped.
				}).catch((err) => {
					// Stop failed, handle it.
				});
			}
		}
		catch (err) {
			//console.log(err);
			this.cameraRunning = false;
		}
	}

	cancelScan() {
		//const html5QrCode = new Html5Qrcode(/* element id */ "reader");
		try {
			if (this.html5QrCode.isScanning === true) {
				this.scanning = false;
				this.cameraRunning = false;
				this.html5QrCode.stop().then((ignore) => {
					// QR Code scanning is stopped.
				}).catch((err) => {
					// Stop failed, handle it.
				});
			}
			else {
				this.scanning = false;
				this.cameraRunning = false;
			}
		}
		catch (err) {
			//console.log(err);
			this.cameraRunning = false;
		}
	}

	fileChangeListener($event: any): void {

		// Select the files from the event
		const files = $event.srcElement.files;

		// Parse the file you want to select for the operation along with the configuration
		this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
			.pipe().subscribe((result: Array<any>) => {
				//console.log('Result', result);
				this.csvRecords = result;
			}, (error: NgxCSVParserError) => {
				//console.log('Error', error);
			});

	}

	csvSelect() {
		if (this.singleSelect == false) {
			this.singleSelect = true;
			this.bulkSelect = false;
			//console.log("singleSelect");
			return;
		}
		if (this.singleSelect == true) {
			this.singleSelect = false;
			this.bulkSelect = true;
			//console.log("bulkSelect");
			return;
		}
	}


	// Removes any special characters in order to sanitize input.
	validateInput(fieldInput, element) {
		var temp = fieldInput;
		fieldInput = fieldInput.replace(/[^\d\s\p{Ll},.\\()/-]/giu, " "); // Regex pattern that matches any letter from a-z, nordic characters and digits 0-9
		//fieldInput = fieldInput.replace(/[^\d\p{Ll}].-\//giu, " "); // Regex pattern that matches any letter from a-z, nordic characters and digits 0-9
		//console.log(element + " model: " + fieldInput);
		let myInput: any = document.querySelector('input[name="'+element+'"]');
		myInput.value = fieldInput;

		switch (element)
		{
			case 'serial':
				this.biosSerial = fieldInput;
				if (temp != fieldInput) {
					this.validateSerialError = true;
				}
				else {
					this.validateSerialError = false;
				}
				break;

			case 'manufacturer':
				this.manufacturer = fieldInput;
				if (temp != fieldInput) {
					this.validateManufacturerError = true;
				}
				else {
					this.validateManufacturerError = false;
				}
				break;


			case 'model':
				this.model = fieldInput;
				if (temp != fieldInput) {
					this.validateModelError = true;
				}
				else {
					this.validateModelError = false;
				}
				break;
		}
		this.generateModel();

	}
	
	//createAsset() {
	//	if (this.enteredField == null || this.enteredString == null) {
	//		alert("Please select a search field and a search term!");
	//		return;
	//	}
	//}

	/*
	searchSerial = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(() => this.searching = true),
			switchMap(term =>
				this.apiService.searchSerial(String(term)).pipe(
					tap(() => this.searchFailed = false),
					catchError(() => {
						this.searchFailed = true;
						return of([]);
					}))
			),
			tap(() => this.searching = false)
		);
		
	notFound() {
		console.log("Asset not found");
		alert("Asset not found");
	}*/

	generateModel(): any {
		try {
			this.emptyPost = false;
			this.currentCustomer = NavbarComponent.currentCustomer;

			this.xmlModel = new XmlModel();
			this.xmlModel.XMLFolder = AppConfig.settings.apiSettings.snowXmlFolder;
			this.xmlModel.SerialNumber = this.biosSerial;
			this.xmlModel.Manufacturer = this.manufacturer;
			this.xmlModel.Model = this.model;

			if ((this.xmlModel.SerialNumber != "" && this.xmlModel.SerialNumber != undefined) && (this.xmlModel.Manufacturer != "" && this.xmlModel.Manufacturer != undefined) && (this.xmlModel.Model != "" && this.xmlModel.Model != undefined)) {
				this.emptyPost = false;
				//console.log(this.xmlModel);
			}
			else {
				this.emptyPost = true;
				//console.log(this.xmlModel);
			}

		} catch (e) {
			alert(e);
		}
	}

	private postToServer(model): any {
		if ((model.SerialNumber != "" && model.SerialNumber != undefined) && (model.Manufacturer != "" && model.Manufacturer != undefined) && (model.Model != "" && model.Model != undefined)) {
			this.apiService.createAsset(model);
		}
		else {
			alert("Please fill in all the fields.");
			return;
		}
	}

	postBulkToServer(): any {
		var assetErrors = [];
		var errorLines: string = "";
		var haveCsvErrors: boolean = false;
		try {
			this.csvRecords.forEach(asset => {
				this.xmlModel = new XmlModel();
				this.xmlModel.XMLFolder = AppConfig.settings.apiSettings.snowXmlFolder;
				this.xmlModel.SerialNumber = asset.Serialnumber;
				this.xmlModel.Manufacturer = asset.Manufacturer;
				this.xmlModel.Model = asset.Model;
				if ((this.xmlModel.SerialNumber != "" && this.xmlModel.SerialNumber != undefined) && (this.xmlModel.Manufacturer != "" && this.xmlModel.Manufacturer != undefined) && (this.xmlModel.Model != "" && this.xmlModel.Model != undefined)) {
					this.apiService.createAsset(asset);
				}
				else {
					let value = this.csvRecords.find(o => o[0] == asset[0]);
					assetErrors.push(asset[0]);
					if (value[0] != "") {
						errorLines += value[0] + ", ";
					}
					else if (value[1] != "") {
						errorLines += value[1] + ", ";
					}
					haveCsvErrors = true;
				}
			});
			if (haveCsvErrors) {
				alert("Assets with the following serial numbers are causing issues and have not been created: " + errorLines);
			}
		} catch (e) {
			alert(e);
		}
	}

	private readyToPost() {
		this.postedValues = true;
	}

	private cancelPost() {
		this.postedValues = false;
	}

	/*openModal(asset: any) {
		const modalRef = this.modalService.open(ModalContentComponent);
		modalRef.componentInstance.asset = asset;
		modalRef.result.then((result) => {
			if (result) {
				//console.log(result);
			}
		});
	}*/
}