import { Injectable, Inject, } from '@angular/core';
import { SessionService } from '../services/session.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { TokenObject } from '../classes/classes';

@Injectable()
export class AuthenticationService {

    baseUrl: string;


    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, public session: SessionService) {
        this.baseUrl = baseUrl;
    }

    isTokenExpired(token: string) {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }

    refreshAccessToken(): Observable<string> {
        var headers_object = new HttpHeaders();
        headers_object = headers_object.append('Content-Type', 'application/json; charset=utf-8');
        headers_object = headers_object.append("Accept", "application/json; charset=utf-8");
        var httpOptions = {
            headers: headers_object
        };

        var tokenObject = new TokenObject();
        tokenObject.token = this.session.getToken();
        tokenObject.refreshtoken = this.session.getRefreshToken();

        return this.http.post<any>(this.baseUrl + 'token/refreshtoken', tokenObject, httpOptions);
    }
}
