import { HttpClient, HttpHeaders, HttpClientModule, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { ObjectModel } from '../classes/classes';
import { AppConfig } from '../app.config';
import { LoginResultModel } from '../classes/classes';
import { DataService } from "../services/data.service";
import { SessionService } from '../services/session.service';
import { mergeMap } from "rxjs/operators";
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ApiService {

	headers_object: HttpHeaders;
	baseUrl: string;
	httpOptions: { headers: HttpHeaders };
	cid: string;
	session: SessionService;

	constructor(private http: HttpClient, private sess: SessionService, private dataService: DataService, private authService: AuthenticationService, @Inject('BASE_URL') baseUrl: string) {
		this.baseUrl = baseUrl;
		this.session = sess;
		this.headers_object = new HttpHeaders();
		this.headers_object = this.headers_object.append('Content-Type', 'application/json; charset=utf-8');
		this.headers_object = this.headers_object.append("Accept", "application/json; charset=utf-8");
		this.headers_object = this.headers_object.append("Authorization", "Bearer " + this.session.getToken());
		this.httpOptions = {
			headers: this.headers_object
		};

		this.dataService.currentCustomerId.subscribe(cid => this.cid = cid);
	}

	searchLocation(term: string): Observable<any> {
		if (term == '') {
			return of([]);
		}
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + this.cid + '/searchlocations/' + term, this.httpOptions);
	}

	swapLog(logLevel: string, message: string, customerId: string, userName: string, assetId: string, timeStamp: string) {
		let logObject = { logLevel, message, customerId, userName, assetId, timeStamp };
		this.http.post<any>(this.baseUrl + 'api/log/swap/', logObject, this.httpOptions).subscribe(() => {
			console.log(logObject);
		}, error => console.error(error));
	}

	// This function gets a list of all the objects registered on a customer (StockLocations in this instance)
	getLocation() {
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + this.cid + '/locations/', this.httpOptions);
	}

	getCostCenter() {
		return this.http.get<any>(this.baseUrl + 'api/slm/costcenter/' + this.cid, this.httpOptions);
	}
	
	/*postAsset(asset: any) {
		this.http.post(this.baseUrl + 'api/slm/customers/post/' + this.cid, asset, this.httpOptions).subscribe(() => {
			console.log("cid:", this.cid, " asset: ", asset)
			//window.location.reload();
		}, error => console.error(error));
	}*/

	// Points to CsvController.cs
	postAsset(asset: any) {
		this.http.post(this.baseUrl + 'api/csv/customers/' + this.cid + '/', asset, this.httpOptions).subscribe(() => {
			window.location.reload();
		}, error => console.error(error));
	}

	/* postSwapAssets(oldAsset: ObjectModel, newAsset: ObjectModel) {
		this.http.post(this.baseUrl + 'api/csv/customers/' + this.cid + '/', oldAsset, this.httpOptions)
			.pipe(
				mergeMap(() => {
					return this.http.post(this.baseUrl + 'api/csv/customers/' + this.cid + '/', newAsset, this.httpOptions)
				;
			})
			)
			.subscribe(() => {
				window.location.reload();
			}, error => console.error(error));
	} */

	postSwapOld(oldAsset: ObjectModel) {
		this.http.post(this.baseUrl + 'api/csv/customers/' + this.cid + '/swap/old', oldAsset, this.httpOptions).subscribe(() => {
		}, error => console.error(error));
	}

	postSwapNew(newAsset: ObjectModel) {
		this.http.post(this.baseUrl + 'api/csv/customers/' + this.cid + '/swap/new', newAsset, this.httpOptions).subscribe(() => {
			window.location.reload();
		}, error => console.error(error));
	}

	createAsset(asset: any) {
		this.http.post(this.baseUrl + 'api/snowxml/customers/' + this.cid + '/', asset, this.httpOptions).subscribe(() => {
			window.location.reload();
		}, error => console.error(error));
	}

	postOperationalState(serial: any) {
		this.http.post(this.baseUrl + 'api/slm/customers/' + this.cid + '/operational/' + serial, null, this.httpOptions).subscribe(() => {
			//console.log("serial:", serial);
			//window.location.reload();
		}, error => console.error(error));
	}

	// This function is fired when the user starts typing on the /search page.
	// A drop-down menu is populated with all devices that match with the search string(term).
	searchSerial(term: string, cid: string = ''): Observable<any> {
		if (term.startsWith('s') || term.startsWith('S')) {
			//console.log(term);
			term = term.substring(1);
			//console.log(term);
		}
		if (term == '') {
			return of([]);
		}
		var customerId = cid === '' ? this.cid : cid;
		//console.log(term);
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + customerId + '/searchassets/' + term, this.httpOptions);
	}

	getPortalSerial(cid: string, serial: string): Observable<any> {
		if (cid == '' || serial == '') {
			return of([]);
		}
		return this.http.get<any>(this.baseUrl + 'api/portal/' + cid + '/' + serial, this.httpOptions);
	}

	getPortalConfig(): Observable<any> {
		return this.http.get<any>(this.baseUrl + 'api/portal/config', this.httpOptions);
	}


	// When selecting a device on the /search page, this function is fired.
	// This function queries SQL for a specific device and all associated fields.
	getSelfComputer(computer: any, cid: string = ''): Observable<any> {
		var customerId = cid === '' ? this.cid : cid;
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + customerId + '/assetdetailsweb/' + computer.id, this.httpOptions);
	}

	login(username: string, password: string): Observable<any> {
		var headers_object = new HttpHeaders();
		headers_object = headers_object.append('Content-Type', 'application/json; charset=utf-8');
		headers_object = headers_object.append("Accept", "application/json; charset=utf-8");
		headers_object = headers_object.append("Authorization", "Basic " + btoa(username + ":" + password));
		var httpOptions = {
			headers: headers_object
		};
		return this.http.post<any>(this.baseUrl + 'api/login', null, httpOptions);
	}

	refreshAccessToken(): Observable<any> {
		return this.authService.refreshAccessToken();
	}

	isTokenExpired(token: string) {
		return this.authService.isTokenExpired(token);
	}

	getCustomers(): Observable<any> {
		var headers_object = new HttpHeaders();
		headers_object = headers_object.append('Content-Type', 'application/json; charset=utf-8');
		headers_object = headers_object.append("Accept", "application/json; charset=utf-8");
		headers_object = headers_object.append("Authorization", "Bearer " + this.session.getToken());
		var httpOptions = {
			headers: headers_object
		};
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/', httpOptions);
	}

	getSerialNumbers(field: string, searchTerm: string): Observable<any> {
		if (searchTerm.startsWith('s') || searchTerm.startsWith('S')) {
			//console.log(searchTerm);
			searchTerm = searchTerm.substring(1);
			//console.log(searchTerm);
		}
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + this.cid + '/serialsearch/' + field + '/' + searchTerm, this.httpOptions);
	}

	// Get customer state config
	getCustomerStateConfig() {
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + this.cid + '/stateconfig', this.httpOptions);
	}

	getVersion() {
		return this.http.get<any>(this.baseUrl + 'api/slm/version/', this.httpOptions);
	}

	private handleError(error: HttpErrorResponse) {
		if (error.status === 0) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}
		// Return an observable with a user-facing error message.
		return throwError(
			'Something bad happened; please try again later.');
	}

	// Query SQL database for devices that match with current search string
	searchCustomFields(field: string, searchTerm: string): Observable<any> {
		if (searchTerm.startsWith('s') || searchTerm.startsWith('S')) {
			//console.log(searchTerm);
			searchTerm = searchTerm.substring(1);
			//console.log(searchTerm);
		}
		//console.log("searchCustomFields fired");
		//console.log("field", field);
		var regexField = field.replace(/20/g, " ");
		//console.log(regexField);
		//var asdf = this.baseUrl + 'api/slm/customers/' + this.cid + '/searchcustomfields/' + field + '/' + searchTerm;
		//var fdsa = this.baseUrl + 'api/slm/customers/' + this.cid + '/searchcustomfields/' + regexField + '/' + searchTerm;
		//console.log(asdf);
		//console.log(fdsa);
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + this.cid + '/searchcustomfields/' + regexField + '/' + searchTerm);
		//return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + this.cid + '/searchcustomfields/?field=' + field + '&searchTerm=' + searchTerm);
	}

	searchComputerName(searchTerm: string): Observable<any> {
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + this.cid + '/searchcomputername/' + searchTerm);
	}

/*	fetchWithCredentials(url, options): any {
	    var jwtToken = this.session.getToken();
	    options = options || {};
	    options.headers = options.headers || {};
	    options.headers['Authorization'] = 'Bearer ' + jwtToken;

	    this.http.get<any>(url, options).subscribe(
	        r => {
	            r.
	        })
	    return res;

	    //        if (response.status === 401 && response.headers.has('Token-Expired')) {
	    //            var refreshToken = this.session.getRefreshToken();

	    //            this.refresh(jwtToken, refreshToken).subscribe(
	    //                refreshResponse => {
	    //                    if (!refreshResponse.ok) {
	    //                        return response; //failed to refresh so return original 401 response
	    //                    }
	    //                    var jsonRefreshResponse = refreshResponse.json(); //read the json with the new tokens

	    //                    this.session.setToken(jsonRefreshResponse.token);
	    //                    this.session.setRefreshToken(jsonRefreshResponse.refreshToken);
	    //                    return this.fetchWithCredentials(url, options); //repeat the original request
	    //                });
	    //        } else { //status is not 401 and/or there's no Token-Expired header
	    //            return response; //return the original 401 response
	    //        }

	    //    });
	}
	
	refresh(jwtToken, refreshToken) {
	    var body = `token=${encodeURIComponent(jwtToken)}&refreshToken=${encodeURIComponent(refreshToken)}`;
	    var headers_object = new HttpHeaders();
	    headers_object = headers_object.append('Content-Type', 'application/x-www-form-urlencoded');
	    var httpOptions = {
	        headers: headers_object
	    };
	    return this.http.post<any>(this.baseUrl + 'token/refresh', body, httpOptions);

	}*/
}
