import { Component, OnInit, Inject, NgZone, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser'
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { Computer, SlmObject, ObjectModel } from "../../classes/classes";
import { ApiService } from '../../services/ApiService.class';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { NavbarComponent } from '../../nav/navbar/navbar.component';
import { SidebarComponent } from '../../nav/sidebar/sidebar.component';
//import { CameraService } from "../../services/CameraService";
import { Html5Qrcode } from "html5-qrcode";
import { LoginPageComponent } from '../../login-page/login-page.component';
import { SessionService } from '../../services/session.service';


@Component({
	selector: "app-assetsearch",
	templateUrl: "./assetsearch.component.html",
	providers: [ApiService],
	styleUrls: ["./assetsearch.component.css"]
})
export class AssetsearchComponent implements OnInit {

	emptyPostRef: any;

	splitPurchaseDate: string;

	searchActive: boolean = false;

	locisCollapsed: boolean;
	statisCollapsed: boolean;
	opstatisCollapsed: boolean;
	locationSelected: boolean;

	toggleSerial: boolean = false;

	customerAssetName: string = "";
	productCategory: string = "";

	lifeCycleError: string = "";
	opStateError: string = "";

	collapseCustom: boolean = false;

	postedValues: boolean = false;

	endOfLease: Date;
	//lcState: any;
	//opState: any;
	//locState: any;
	model: SlmObject;
	purchaseDateExpired = false;
	validateOrderedError = false;
	validateCountryError = false;
	validateLocationError = false;
	validateVendorError = false;
	searching = false;
	searchingloc = false;
	searchFailed = false;

	emptyPost = false;

	objModel = new ObjectModel();

	newValues = [];
	oldValues = [];
	changedFields = [];
	filteredLifecycles = [];
	filteredOpStates = [];
	stocklocation = [];
	stockFiltered = [];
	costCenter: any;
	filteredCostCenter: any;
	totalCostCenter: any;

	currentCustomer: any;

	purchaseExpiredText: string;

	ua = navigator.userAgent;
	deviceType: string = "";

	currentAssetCostCenter = null;
	currentStockLocation = null;
	//currentStockLocationName = null;
	useCountry = false;
	useLocation = false;
	useOrderedFor = false;
	useVendorOrderNumber = false;
	orderedFor = null;
	vendorOrderNumber = null;
	//orderedFormat: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
	country = null;
	location = null;
	
	selectedLifecycle = null;
	selectedConfigLifeCycle = null;
	currentOperationalStates = null;
	selectedStockLocation = null;
	selectedOpstate = null;
	selectedCostCenter = null;
	
	hasLocation = null;
	useCostCenter = null;
	category = null;

	currentcomputer: any = null;
	currentcomputerCopy: any = null;
	cid: string;

	// camservice;
	// Camera related variables
	cameraId;
	scanning: boolean;
	html5QrCode;
	availableCameras;
	selectedCamera = null;
	scanField: string;
	cameraChosen: boolean = false;
	cameraRunning: boolean = false;

	lookup;
	computername;

	refDetect: ChangeDetectorRef;

	_http: HttpClient;
	_baseUrl: string;

	locreslist: Array<any>;

	stateConfig: any;

	lifecycles: any = [];

	opList: any = [];
	opstates: any = [];
	customerStateConfig: any;

	sessionService: SessionService;
	loginComponent: LoginPageComponent;

	async ngOnInit() {
		await this.fetchLocation();		
		this.cid = this.apiService.cid;
		this.isMobile();
		this.searchActive = false;
		await this.getCostCenter();
		await this.getCameras();
		this.sessionService = new SessionService();
		setTimeout(() => {
			this.getStateConfig();
		}, 500);
	}

	constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private apiService: ApiService,
		private zone: NgZone, ref: ChangeDetectorRef) {
		this._http = http;
		this._baseUrl = baseUrl;
		this.currentcomputer = new Computer();
		this.refDetect = ref;
		this.currentCustomer = NavbarComponent.currentCustomer;

		window['barcodeValidate'] = {
			zone: this.zone,
			componentFn: (value: string) => this.getAsset(value),
			component: this,
		};
	}

	formatter = (result: any) => result.objectname;
	computerformatter = (result: any) => result.biosSerialNumber;
	computerNameFormatter = (result: any) => result.Name;

	//search = (text$: Observable<string>) =>
	//	text$.pipe(
	//		debounceTime(300),
	//		distinctUntilChanged(),
	//		tap(() => this.searchingloc = true),
	//		switchMap(term =>
	//			this.apiService.searchLocation(String(term)).pipe(
	//				tap(() => this.searchFailed = false),
	//				catchError(() => {
	//					this.searchFailed = true;
	//					return of([]);
	//				}))
	//		),
	//		tap(() => this.searchingloc = false)
	//	);

	async getStateConfig() {
		this.customerStateConfig = JSON.parse(await this.sessionService.getCustomerStateConfig());
	}
	
	isMobile() {
		//let check = false;
		//(function (a) {
		//	if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
		//		check = true;
		//})(navigator.userAgent || navigator.vendor || window.opera);
		//return check;
		
		
		if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(this.ua)) {
			return "tablet";
		}
		if (
			/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
				this.ua
			)
		) {
			this.deviceType = "mobile";
		}
		this.deviceType = "desktop";
	};

	toggleSerialComputername() {
		if (this.toggleSerial) {
			this.toggleSerial = false;
			return;
		}
		if (!this.toggleSerial) {
			this.toggleSerial = true;
			return;
		}
	}

	removeAssettable() {
		const details = document.getElementById("computerdetails");
		const assetTable = document.getElementById("assettable");
		details.classList.remove("opened");
		assetTable.classList.remove("opened");
	}

	// This function runs whenever the user starts typing in the #lookup input field
	searchSerial = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(() => this.searchActive = false),
			tap(() => this.searching = true),
			switchMap(term =>
				this.apiService.searchSerial(String(term)).pipe(
					tap(() => this.searchFailed = false),
					catchError(() => {
						this.searchFailed = true;
						return of([]);
					}))
			),
			tap(() => this.searching = false),
		);

	searchComputerName = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(() => this.searchActive = false),
			tap(() => this.searching = true),
			switchMap(term =>
				this.apiService.searchComputerName(String(term)).pipe(
					tap(() => this.searchFailed = false),
					catchError(() => {
						this.searchFailed = true;
						return of([]);
					})
				)
			),
			tap(() => this.searching = false),
		);

	async getCameras() {
		Html5Qrcode.getCameras().then(devices => {
			/**
			 * devices would be an array of objects of type:
			 * { id: "id", label: "label" }
			 */

			if (devices && devices.length) {
				if (devices.length < 2) {
					this.cameraId = devices[0].id;
				}
				this.availableCameras = devices;
			}
		}).catch(err => {
			// handle err
		});
	}

	prepareScanning(field: string) {
		this.scanning = true;
		this.scanField = field;
		if (this.availableCameras.length < 2) {
			this.cameraChosen = true;
			this.startScanning();
		}
		else if (this.html5QrCode) {
			this.startScanning();
		}
	}

	cameraSelected(camera: any) {
		this.cameraChosen = true;
		try {
			if (this.availableCameras.length > 1 && this.html5QrCode.isScanning === true) {
				this.swapCamera();
				this.cameraId = camera;
				this.startScanning();
			}
			else {
				this.cameraId = camera;
				this.startScanning();
			}
		}
		catch {
			this.cameraId = camera;
			this.startScanning();
		}
	}

	startScanning() {
		this.cameraRunning = true;
		this.html5QrCode = new Html5Qrcode(/* element id */ "reader");
		this.lookup = document.getElementById("lookup");
		this.computername = document.getElementById("computername");
		const config = {
			fps: 10,
			qrbox: { width: 250, height: 250 },
			rememberLastUsedCamera: true
			// Only support camera scan type.
		};
		// If you want to prefer back camera

		const qrCodeSuccessCallback = (decodedText, decodedResult) => {
			/* handle success */
			if (this.scanField === "serialComputer") {
				if (this.toggleSerial === true) {
					//this.searchComputerName(decodedText);
					this.scanning = false;
					this.computername.value = decodedText;
					this.computername.dispatchEvent(new Event('input'));
					this.computername.focus();
					this.html5QrCode.stop().then((ignore) => {
						// QR Code scanning is stopped.
					}).catch((err) => {
						// Stop failed, handle it.
					});
				}
				else {
					//this.searchSerial(decodedText);
					this.scanning = false;
					this.lookup.value = decodedText;
					this.lookup.dispatchEvent(new Event('input'));
					this.lookup.focus();
					this.html5QrCode.stop().then((ignore) => {
						//QR Code scanning is stopped.
					}).catch((err) => {
						// Stop failed, handle it.
					});
				}
			}
		}

		if (this.deviceType == "mobile") {
			this.html5QrCode.start({ facingMode: "environment" });
		}
		else if (this.deviceType == "desktop") {
			this.html5QrCode.start({ deviceId: { exact: this.cameraId } }, config, qrCodeSuccessCallback);
		}

	}

	swapCamera() {
		try {
			if (this.html5QrCode.isScanning === true) {
				this.cameraRunning = false;
				this.html5QrCode.stop().then((ignore) => {
					// QR Code scanning is stopped.
				}).catch((err) => {
					// Stop failed, handle it.
				});
			}
		}
		catch (err) {
			this.cameraRunning = false;
		}
	}	

	cancelScan() {
		//const html5QrCode = new Html5Qrcode(/* element id */ "reader");
		try {
			if (this.html5QrCode.isScanning === true) {
				this.scanning = false;
				this.cameraRunning = false;
				this.html5QrCode.stop().then((ignore) => {
					// QR Code scanning is stopped.
				}).catch((err) => {
					// Stop failed, handle it.
				});
			}
			else {
				this.scanning = false;
				this.cameraRunning = false;
			}
		}
		catch (err) {
			this.cameraRunning = false;
		}
	}

	removeMenu() {
		SidebarComponent.prototype.removeMenu();
	}

	// Gets the StockLocations associated with the current customer ID.
	async fetchLocation() {
		this.apiService.getLocation().subscribe(res => {
			this.stocklocation = res;
		}, error => {
		}, () => {

		});
	}

	// This pulls the selected customer's cost centers from the SQL database
	async getCostCenter() {
		//this.currentAssetCostCenter = "";
		this.useCostCenter = false;
		this.apiService.getCostCenter().subscribe(res => {
			if (res.costCenter != null) {
				this.costCenter = res.costCenter.split(",").slice();
			} else {
				this.costCenter = [];
			}
		}, error => {
		}, () => {

		});
	}

	setCostCenter() {
		let customf = null;

		for (let i = 0; i < this.currentcomputer.customFields.length; i++) {
			if (this.currentcomputer.customFields[i].name == "Cost center") {
				// stock is set to the index of the element
				customf = i;
				break;
			}
		}

		if (customf != null) {
			this.currentAssetCostCenter = this.currentcomputer.customFields[customf].value;
			this.selectedCostCenter = this.currentAssetCostCenter;
			this.costCenter.push(" ");
		}

		if ((this.costCenter.length > 0) && (this.costCenter[0] != "")) {
			this.useCostCenter = true;
			let cost = null;
			for (let i = 0; i < this.costCenter.length; i++) {
				if (this.costCenter[i] == this.selectedCostCenter) {
					// stock is set to the index of the element
					cost = i;
					break;
				}
			}
			if (cost != null) {
				this.totalCostCenter = this.costCenter.slice();
				this.filteredCostCenter = this.costCenter.splice(cost, 1);
			}
		}
	}

	// This removes the lifecycle state that matches with that of currentcomputer and adds it to a separate array (to prevent duplicate entries in the dropdown).
	filterLifeCycles() {
		if (this.currentcomputer.lifeCycleState != null && this.currentcomputer.lifeCycleState.toLowerCase() == "retired") {
			this.selectedConfigLifeCycle = this.customerStateConfig.find(l => l.acceptedLifeCycle.toLowerCase() == "retired");
			this.lifecycles = this.lifecycles.indexOf(this.lifecycles.find(o => o.acceptedLifeCycle.toLowerCase() == "retired"));
			this.selectedOpstate = this.currentcomputer.operationalState;
		}
		else {
			this.selectedConfigLifeCycle = this.customerStateConfig.find(l => l.acceptedLifeCycle.toLowerCase() == this.selectedLifecycle.toLowerCase());
			this.lifecycles = this.customerStateConfig.slice();
			
			let cycleFound = this.lifecycles.indexOf(this.lifecycles.find(o => o.acceptedLifeCycle.toLowerCase() == this.currentcomputer.lifeCycleState.toLowerCase()));	
			
			if (cycleFound) {
				let spliced = this.lifecycles.splice(cycleFound, 1);
			}
			this.selectedOpstate = this.currentcomputer.operationalState;
		}
	}

	updateStates() {
		this.selectedConfigLifeCycle = this.selectedLifecycle ? this.customerStateConfig.find(l => l.acceptedLifeCycle.toLowerCase() == this.selectedLifecycle.toLowerCase()) : null;
		this.currentOperationalStates = this.selectedConfigLifeCycle.acceptedOperational.slice();
		let ops = this.currentOperationalStates.slice();

		let spliced = null;
		if (ops.find(o => o.toLowerCase() == this.currentcomputer.operationalState.toLowerCase())) {
			let index = ops.findIndex(o => o.toLowerCase() == this.currentcomputer.operationalState.toLowerCase());
			spliced = ops.splice(index, 1);
		}
		this.currentOperationalStates = ops.slice();

		if (this.selectedLifecycle.toLowerCase() == "ordered - awaiting receipt") {
			this.locationSelected = false;
		}
		else if (this.selectedLifecycle.toLowerCase() == "in stock") {
			this.locationSelected = true;
		}
		else if (this.selectedLifecycle.toLowerCase() == "in use") {
			this.locationSelected = false;
		}
		else if (this.selectedLifecycle.toLowerCase() == "returned for maintenance") {
			this.locationSelected = true;
		}
		else if (this.selectedLifecycle.toLowerCase() == "retired") {
			this.locationSelected = true;
		}
		else if (this.selectedLifecycle.toLowerCase() == "returned to supplier") {
			this.locationSelected = true;
		}
		else if (this.selectedLifecycle.toLowerCase() == "missing") {
			this.locationSelected = false;
		}

	}

	// Only displays OrderedFor if the customer ID matches with 1 (DEMO), 3 (Storebrand), 23 (Systembolaget) or 24 (Aibel)
	showOrderedFor() {
		if (["1", "3", "23", "24"].includes(this.cid)) {
			this.useOrderedFor = true;
			try {
				this.orderedFor = this.currentcomputer.orderedFor;		
			}
			catch (error) {
				this.orderedFor = "";
			}
		}
		else {
			this.useOrderedFor = false;
		}
	}

	showVendorOrderNumber() {
		if (["1","23"].includes(this.cid)) {
			this.useVendorOrderNumber = true;
			try {
				let isVendor = this.currentcomputer.customFields.find(o => o.name.toLowerCase() === 'vendor order number').value;
				if (isVendor) {
					this.vendorOrderNumber = isVendor;
				}
			}
			catch (error) {
				//this.vendorOrderNumber = "";
			}
		}
		else {
			this.useVendorOrderNumber = false;
		}
	}

	// Only displays Country and Location if the customer ID matches with 1 (DEMO) or 3 (Storebrand)
	showCountryLocation() {
		if (["1", "3"].includes(this.cid)) {
			this.useCountry = true;
			this.useLocation = true;
			try {
				this.country = this.currentcomputer.customFields.find(o => o.name.toLowerCase() === 'country').value;
				this.location = this.currentcomputer.customFields.find(o => o.name.toLowerCase() === 'location').value;
			} catch (error) {
				this.country = null;
				this.location = null;
			}
		}	
	}

	// Removes any special characters in order to sanitize input.
	validateInput(fieldInput, element) {
		let temp = fieldInput;
		/*fieldInput = fieldInput.replace(/[^\w\s]/giu, "");*/
		/*fieldInput = fieldInput.replace(/[^\p{L}\d\s]/giu, "");*/
		fieldInput = fieldInput.replace(/[^\d\s\p{Ll},.\\()/-]/giu, " "); // Regex pattern that filters out all special characters except for comma and space
		//fieldInput = fieldInput.replace(/[^\d\p{L}\s]/gui, ""); // Regex pattern that filters out all special characters except for comma and space
		let myInput: any = document.querySelector('input[name="' + element + '"]');
		if (temp != fieldInput) {
			myInput.value = fieldInput;
		}

		if (element == "orderedFor") {
			this.orderedFor = fieldInput;
			if (temp != fieldInput) {
				this.validateOrderedError = true;
			}
			else {
				this.validateOrderedError = false;
			}
		}

		else if (element == "country") {
			this.country = fieldInput;
			if (temp != fieldInput) {
				this.validateCountryError = true;
			}
			else {
				this.validateCountryError = false;
			}
		}

		else if (element == "location") {
			this.location = fieldInput;
			if (temp != fieldInput) {
				this.validateLocationError = true;
			}
			else {
				this.validateLocationError = false;
			}
		}

		else if (element == "vendorOrderNumber") {
			this.vendorOrderNumber = fieldInput;
			if (temp != fieldInput) {
				this.validateVendorError = true;
			}
			else {
				this.validateVendorError = false;
			}
		}
		
	}

	// This function runs whenever the user selects an asset from the drop-down menu
	selectedItem(item) {
		this.cid = this.apiService.cid;
		this.apiService.getSelfComputer(item.item).subscribe(res => {
			this.currentcomputer = res;
		}, error => {
		}, () => {
			//this.getStateConfig();
			this.searchActive = true;
			this.collapseCustom = false;
			this.resetProperties();

			let computerFilteredLifeCycle = this.customerStateConfig.find(o => o.acceptedLifeCycle.toLowerCase() === this.currentcomputer.lifeCycleState.toLowerCase());
			if (computerFilteredLifeCycle) {
				this.currentcomputer.lifeCycleState = computerFilteredLifeCycle.acceptedLifeCycle;
			}
			let computerFilteredOperationalState = computerFilteredLifeCycle.acceptedOperational.find(o => o.toLowerCase() === this.currentcomputer.operationalState.toLowerCase());
			if (computerFilteredOperationalState) {
				this.currentcomputer.operationalState = computerFilteredOperationalState;
			}
			
			this.selectedStockLocation = this.currentcomputer.stockLocation;
			this.selectedLifecycle = this.currentcomputer.lifeCycleState;

			try {
				this.customerAssetName = this.currentcomputer.customFields.find(o => o.name.toLowerCase() == 'customer asset name').value;
			} catch (e) {
				//alert(e);
			}
			
			this.selectedConfigLifeCycle = this.customerStateConfig.find(o => o.acceptedLifeCycle.toLowerCase() == this.selectedLifecycle.toLowerCase());
			this.currentOperationalStates = this.selectedConfigLifeCycle.acceptedOperational.slice();
			
			this.filterLifeCycles();
			this.updateStates();
			this.setCostCenter();
			this.showOrderedFor();
			this.showVendorOrderNumber();
			this.showCountryLocation();
			setTimeout(() => {
				this.generateModel();
			}, 300);		
		});

		const details = document.getElementById("computerdetails");
		const assetTable = document.getElementById("assettable");
		details.classList.add("opened");
		assetTable.classList.add("opened");
	}

	// Related to the mobile app
	getAsset(inputValue: string) {
		if (inputValue.indexOf("|") > 0)
			this.getLocation(inputValue);
		else
			this.getSlmComputer(inputValue);
	}

	// Related to the mobile app
	@ViewChild("collapseSerial", { static: true })
	private collapseSerialRef: ElementRef;
	// Related to the mobile app
	private getSlmComputer(serialNumber: string) {
		this.apiService.searchSerial(serialNumber).subscribe(res => {
			this.apiService.getSelfComputer(res[0]).subscribe(
				r => {
					this.currentcomputer = r;
					this.collapseSerialRef.nativeElement.click();
				});
		});
	}

	// Related to the mobile app
	@ViewChild("collapseLocation", { static: true })
	private collapseLocationRef: ElementRef;
	// Related to the mobile app
	private getLocation(value: string) {
		this.apiService.searchLocation(value).subscribe(res => {
			this.selectedStockLocation = this.currentcomputer.stockLocation;
			this.collapseLocationRef.nativeElement.click();
		});
	}

	// This assembles the object model, and postToServer() passes the object model to the ApiService
	// ApiService then sends the object model to CsvWrite to create a CSV file
	// The CSV file is then placed in a folder and shortly after sent to MCS-SNOW11, where they're read by the inventory server and pushed into the database
	generateModel(): any {
		try {
			this.emptyPost = true;
			this.lifeCycleError = "";
			this.opStateError = "";
			this.currentCustomer = NavbarComponent.currentCustomer;

			this.objModel.CsvFolder = AppConfig.settings.apiSettings.fileShare;
			this.objModel.Serialnumber = this.currentcomputer.biosSerialNumber;
			this.objModel.SimObject = { objectid: "", objecttypeid: "", objectname: "", organization: "", properties: [] };
			this.objModel.CurrentComputer = this.currentcomputer == null ? new Computer() : this.currentcomputer;

			// Lifecycle state
			if (this.selectedLifecycle) {
				if (this.selectedLifecycle.name) { this.objModel.LifeCycleState = this.selectedLifecycle.name; }
				else { this.objModel.LifeCycleState = this.selectedLifecycle; }
			}
			else {
				this.lifeCycleError = "Please select a lifecycle state!";
			}
			

			// Operational state
			/* if (this.selectedLifecycle == "Ordered - Awaiting Receipt") {
				this.selectedOpstate = "";
				this.objModel.OperationalState = this.selectedOpstate;
			}
			else  */if (this.selectedOpstate != null) {
				let ops = this.selectedConfigLifeCycle.acceptedOperational.slice();
				if (this.selectedOpstate) {
					let ope = null;
					for (let i = 0; i < ops.length; i++) {
						if (ops[i].toLowerCase() == this.selectedOpstate.toLowerCase()) {
							// ope is set to the index of the element
							ope = i;
							break;
						}
					}
					if (ope != null) {
						this.objModel.OperationalState = this.selectedOpstate;
					}
					else {
						this.opStateError = this.selectedOpstate.toString() + " is not a valid operational state for the selected lifecycle state!";
						//alert(this.selectedOpstate + " is not a valid Operational State!");
						//return;
					}
					//else {
					//	alert(this.selectedOpstate + " is not a valid Operational State!");
					//	return;
					//}
				}
				else {
					let ope = null;
					for (let i = 0; i < this.opstates.length; i++) {
						if (this.opstates[i].name.toLowerCase() == this.selectedOpstate.toLowerCase()) {
							// ope is set to the index of the element
							ope = i;
							break;
						}
					}
					if (ope != null) {
						this.objModel.OperationalState = this.selectedOpstate;
					}
					else if (this.selectedOpstate == "" || this.selectedOpstate == " ") {
						this.opStateError = "Please select an operational state!";
					}
					else {
						this.opStateError = this.selectedOpstate.toString() + " is not a valid operational state for the selected lifecycle state!";
					}
					//return;
				}
			}
			else {
				this.opStateError = "Please select an operational state!";
			}


			 // Stocklocation
			if (this.stocklocation != null) {
				this.objModel.SimObject = { objectid: "", objecttypeid: "", objectname: "", organization: "", properties: [] };
				this.objModel.SimObject.objectname = this.selectedStockLocation;
				//this.selectedStockLocation = this.currentcomputer.stockLocation;
				/* let stock = null;
				for (let i = 0; i < this.stocklocation.length; i++) {
					if (this.stocklocation[i].objectname == this.selectedStockLocation) {
						// stock is set to the index of the element
						stock = i;
						break;
					}
				}
				if (stock != null) {
					if (this.stocklocation[stock] == "") {
						this.objModel.SimObject = { objectid: "", objecttypeid: "", objectname: "", organization: "", properties: [] };
					} else {
						this.objModel.SimObject = this.stocklocation[stock];
					}
				}
				else if (this.stockFiltered[0]) {
					if (this.selectedStockLocation == this.stockFiltered[0].objectname) {
						this.objModel.SimObject = this.stockFiltered[0];
					}
				} */
			} 


			// Cost center
			if (this.selectedCostCenter != null) {
				let stock = null;

				for (let i = 0; i < this.costCenter.length; i++) {
					if (this.costCenter[i] == this.selectedCostCenter) {
						// stock is set to the index of the element
						stock = i;
						break;
					}
				}

				if (stock) {
					this.objModel.CostCenter = this.costCenter[stock];
				}
				else if (this.selectedCostCenter) {
					this.objModel.CostCenter = this.currentAssetCostCenter;
				}
				else {
					this.objModel.CostCenter = "";
				}				
			}


			// Ordered for
			if (this.orderedFor != null) {
				this.validateInput(this.orderedFor, 'orderedFor');
				this.objModel.OrderedFor = this.orderedFor;
			}


			// Vendor Order Number
			if (this.vendorOrderNumber != null && (this.vendorOrderNumber != this.objModel.VendorOrderNumber)) {
				
				this.validateInput(this.vendorOrderNumber, 'vendorOrderNumber');
				this.objModel.VendorOrderNumber = this.vendorOrderNumber;
			}

			// Country
			if (this.country != null && (this.country != this.objModel.Country)) {
				this.validateInput(this.country, 'country');
				this.objModel.Country = this.country;
			}
			
			// Location
			if (this.location != null && (this.location != this.objModel.Location)) {
				this.validateInput(this.location, 'location');
				this.objModel.Location = this.location;
			}

			this.objModel.UpdatedBy = this.sessionService.getUserName();
			
			this.changedFields.length = 0;
			this.oldValues.length = 0;
			this.newValues.length = 0;

			//this.oldValues = this.currentcomputerCopy;

			if (this.objModel.LifeCycleState != null) { this.compareFields('system', this.objModel.LifeCycleState, 'lifeCycleState'); }
			if (this.objModel.OperationalState != null) { this.compareFields('system', this.objModel.OperationalState, 'operationalState'); }
			if (this.objModel.SimObject.objectname != null) { this.compareFields('system', this.objModel.SimObject.objectname, 'stockLocation'); }
			if (this.objModel.CostCenter != null) { this.compareFields('custom', this.objModel.CostCenter, 'Cost center'); }
			if (this.objModel.OrderedFor != null) { this.compareFields('system', this.objModel.OrderedFor, 'orderedFor'); }
			if (this.objModel.VendorOrderNumber != null ) { this.compareFields('custom', this.objModel.VendorOrderNumber, 'Vendor Order Number'); }
			if (this.objModel.Country != null) { this.compareFields('custom', this.objModel.Country, 'Country'); }
			if (this.objModel.Location != null) { this.compareFields('custom', this.objModel.Location, 'Location'); }

			//this.compare();

			if (this.changedFields.length <= 0) {
				this.emptyPost = true;
			}			

		} catch (e) {
			alert(e);
		}
	}

	private resetProperties() {
		this.objModel = new ObjectModel();
		this.emptyPost = true;
		this.postedValues = false;
		this.changedFields.length = 0;
		this.oldValues.length = 0;
		this.newValues.length = 0;

		this.selectedConfigLifeCycle = null;
		this.currentOperationalStates = null;
		this.country = null;
		this.location = null;
		this.orderedFor = null;
		this.vendorOrderNumber = null;
		this.selectedCostCenter = null;
		this.selectedStockLocation = null;
		this.selectedLifecycle = null;
		this.selectedOpstate = null;
		this.currentAssetCostCenter = null;
	}

	private compareFields(valueType:string, objField: string, compField:string) {
		//let value = this.currentcomputer.customFields.find(o => o.name === compField).value;
		if (objField != null || objField != undefined) {
			valueType = valueType.toLowerCase();
			let oldValue;

			this.postedValues = false;

			if (valueType === 'system') {
				try {
					oldValue = this.currentcomputer[compField];
				}
				catch (e) {
				}
			}
				
			else if (valueType === 'custom') {
				//this.currentcomputer.customFields.includes(compField)
				try {
					let customValue = this.currentcomputer.customFields.find(o => o.name.toLowerCase() === compField.toLowerCase()).value;
					if (customValue != null || customValue != undefined) {
						oldValue = customValue;
					}
					else {
						oldValue = null;
					}
				}
				catch (e) {
				}
			}
			
			if (objField != oldValue) {
				this.changedFields.push(compField);
				this.newValues.push(objField);
				this.oldValues.push(oldValue);
				this.emptyPost = false;
			}
		}
	}

	private postToServer(model): any {
		this.apiService.postAsset(model);
	}

	private readyToPost() {

		this.generateModel();
		this.postedValues = true;
	}

	private cancelPost() {
		this.lifeCycleError = "";
		this.opStateError = "";
		this.postedValues = false;
	}

	toggleVisibility() {
		const details = document.getElementById('computerdetails');
		const main = document.getElementById('main');
	}

}

