import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Computer, Customer } from "../classes/classes";
import { SessionService } from "../services/session.service";


@Injectable()
export class DataService {

    constructor(private session: SessionService) { }

    private customerSource = new BehaviorSubject(new Array<Customer>());
    currentCustomers = this.customerSource.asObservable();

    setCustomers(customers: Array<Customer>) {
        this.customerSource.next(customers);
        this.session.setCustomers(customers);
    }

    private customerIdSource = new BehaviorSubject(this.session.getCustomerId());
    currentCustomerId = this.customerIdSource.asObservable();

    setCustomerId(customerId: string) {
        this.customerIdSource.next(customerId);
        this.session.setCustomerId(customerId);
    }

    private messageSource = new BehaviorSubject(new Computer());
    currentMessage = this.messageSource.asObservable();


    changeMessage(currentcomputer: Computer) {
        this.messageSource.next(currentcomputer);
    }

}
