export class Computer {
	id: string;
	BiosSerialNumber: string;
	Model: string;
	Manufacturer: string;
	Name: string;
	OperatingSystem: string;
	Organization: string;
	Status: string;
	IpAddresses: string;
	DatacenterName: string;
	PhysicalMemoryMb: string;
	NumberOfProcessors: string;
	CoresPerProcessor: string;
	TotalDiskSpaceMb: string;
	TotalDiskSpaceAvailableMb: string;
	NetworkAdapters: string;
	PurchaseDate: string;
	ServiceOffering: string;
	StockManager: string;
	StockCountry: string;
	StockLocation: string;
	StockStreetAddress: string;
	StockPostal: string;
	StockCity: string;
	StockRoom: string;
	StockPosition: string;
	LifeCycleStatus: string;
	CustomerAssetName: string;
	AssetUpdatedBy: string;
	StockSite: string;
	OperationalState: string;
	AssetLastUpdate: string;
	OperationalStateLastUpdated: string;
	LifeCycleStatusLastUpdated: string;
	StockLocationLastUpdated: string;
	OrderedFor: string;
	VendorOrderNumber: string;
	Country: string;
	Location: string;
	ExternalOrderId: string;

	//LogicalDisks: string;
	CustomFields: Array<CustomFields>;

	constructor() {
		this.CustomFields = new Array<CustomFields>();
	}
}

export class CustomFields {
	Name: string;
	Value: string;
}

export class TokenObject {
	token: string;
	refreshtoken: string;
}

export class SlmObject {
	objectid: string;
	objecttypeid: string;
	objectname: string;
	organization: string;

	OrderedFor?: string;
	City?: string;
	PostalCode?: string;
	StreetAddress?: string;
	properties: Array<SlmObjectProperties>;

	constructor() {
		this.properties = new Array<SlmObjectProperties>();
	}
}

export class SlmObjectProperties {
	objectpropertyid: number;
	value: string;
	constructor() { }
}

export class ObjectModel {
	CsvFolder: string;
	Serialnumber: string;
	SimObject: SlmObject;
	LifeCycleState: string;
	CostCenter: string;
	OrderedFor: string;
	VendorOrderNumber: string;
	Country: string;
	Location: string;
	Purchasevalue: string;
	Purchasedate: string;
	Purchasecurrency: string;
	OperationalState: string;
	DeliveryCity: string;
	DeliveryPostalCode: string;
	DeliveryStreet: string;
	//StockLocation: string;
	CurrentComputer: Computer;
	UpdatedBy: string;
	constructor() { }
}

export class XmlModel {
	XMLFolder: string;
	SerialNumber: string;
	Manufacturer: string;
	Model: string;
	Sitename: string;
}

export class UrlObject {
	url: string;
}

export class LoginResultModel {
	token: string;
	error: string;
	customers: Array<Customer>;
}

export class Customer {
	id: string;
	name: string;
}