import { Component } from '@angular/core';
import { ApiService } from "../services/ApiService.class";
import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { DataService } from "../services/data.service";


@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['../sitelayout/sitelayout.component.css']
})
export class LoginPageComponent {

    username = '';
    password = '';

    //sess: SessionService;

    constructor(private api: ApiService, private router: Router, private session: SessionService, private dataService: DataService) {
        //this.sess = session;
    }

    tryLogin() {
        this.api.login(
            this.username,
            this.password
        )
            .subscribe(
                r => {
                    if (r.token) {
                        this.session.setToken(r.token);
                        this.session.setRefreshToken(r.refreshtoken);
                        this.session.setUserName(this.username);
                        this.dataService.setCustomers(r.customers);
                        this.session.setLastRefresh();
                        if (r.customers.length == 2 && r.customers.find(o => o.id == 1) && r.customers.find(o => o.id == 2)) {
                            let toRemove = r.customers.find(o => o.id == 1);
                            r.customers.splice(r.customers.indexOf(toRemove), 1);
                            let custId = r.customers[0].id;
                            this.dataService.setCustomers(r.customers);
                            this.session.setUserRole('customerservice');
                            this.dataService.setCustomerId(custId); 
                        }
                        else if (r.customers.length > 2 && r.customers[0].id == 1) {
                            this.session.setUserRole('admin');
                            this.dataService.setCustomerId(r.customers[0].id);
                        }
                        else {
                            this.dataService.setCustomerId(r.customers[0].id);
                            this.session.setUserRole('default');
                        }
                        this.router.navigateByUrl('search');
                    } else {
                        alert(r.error);
                    }
                },
                r => {
                    alert(r.statusText);
                });
    }

}
