import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../services/ApiService.class';
import { Computer, SlmObject, ObjectModel } from "../../classes/classes";
import { AppConfig } from '../../app.config';


@Component({
    selector: 'app-modal-content',
    providers: [ApiService],
    templateUrl: './modal-content.component.html',
    styleUrls: ['./modal-content.component.css']
})
export class ModalContentComponent implements OnInit {
    @Input() public asset;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    constructor(
        public activeModal: NgbActiveModal,
        private apiService: ApiService
    ) { }

    ngOnInit() {
        //console.log(this.asset.name);
    }

    closeModal() {
        this.activeModal.close(this.asset);
    }

    passBack() {
        this.postToServer(this.asset);
        this.activeModal.close(this.asset);
    }

    postToServer(asset:any): any {
        try {
            //var objModel = new ObjectModel();
            //objModel.CsvFolder = AppConfig.settings.apiSettings.fileShare;
            //objModel.Serialnumber = asset.biosSerialNumber;
            //objModel.SimObject = new SlmObject();
            //objModel.CurrentComputer = asset;
            //objModel.OperationalState = "In Transit from customer";
            this.apiService.postOperationalState(asset.biosSerialNumber);
        } catch (e) {
            alert(e);
        }
    }
}