import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Injectable, Inject, EventEmitter, Output } from '@angular/core';
import { DataService } from "../../services/data.service";
import { SessionService } from "../../services/session.service";
import { Router } from '@angular/router';
import { ApiService } from "../../services/ApiService.class";

@Injectable()
@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {

	constructor(private api: ApiService, private dataService: DataService, private session: SessionService, private router: Router) { }

	customers: any;
	customerid: any;
	static currentCustomer: any;
	setIntervalTimer = 10 * 1000; // 10 seconds in milliseconds
	interval = 15 * 60 * 1000; // 1 minute in milliseconds

	ngOnInit() {
		// Call the checkLoggedIn function initially
		this.checkLoggedIn(true);

		// Schedule the checkLoggedIn function to run every 15 minutes
		setInterval(() =>{
			this.checkLoggedIn();
		},  this.setIntervalTimer);

		this.dataService.currentCustomers.subscribe(customers => this.customers = customers);
		if (this.session.isLoggedIn()) {
			//this.api.getCustomers().subscribe(customers => {
			//    this.customers = customers;
			//    this.dataService.setCustomers(this.customers);
			this.customerid = this.session.getCustomerId();
			this.customers = this.session.getCustomers();
			this.customers.sort((a, b) => a.name.localeCompare(b.name));
			this.api.getCustomerStateConfig().subscribe(res => {
				this.session.setCustomerStateConfig(res.acceptedStates);
			});
			NavbarComponent.currentCustomer = this.customers.find(o => o.id === this.customerid).name;
			//console.log(NavbarComponent.currentCustomer);
			//);
		}
	}

	// Function to check if the user is logged in and refresh token every X minutes
	checkLoggedIn(firstCall: boolean = false):void {
		let lastRefresh = new Date(this.session.getLastRefresh()).getTime();
		let refreshInterval = new Date().getTime() - (this.interval);
		let timeDifference = refreshInterval - lastRefresh;
		if (refreshInterval > lastRefresh) {
			let token = this.session.getToken();
			let tokenExpired = this.api.isTokenExpired(token);
			if (token) {
				if (tokenExpired) {
					this.logout();
				} else {
					this.api.refreshAccessToken().subscribe(
						r => {
							if (r && r.token && r.refreshToken) {
								this.session.setToken(r.token);
								this.session.setRefreshToken(r.refreshToken);
								this.session.setLastRefresh();
							}
						}),
						(err) => {
							console.log(err);
							this.logout();
						};
				}
			} else {
				this.logout();
			}
		}
		//console.log( "Time difference: " + timeDifference);
		//console.log("Interval * 2: " + (this.interval * 2));
		if (firstCall) return;
		if (!firstCall && (timeDifference > (this.interval * 2))) {
			//console.log("Timedifference is greater than interval * 2")
			this.logout();
		}
	}

	menuToggle() {
		var sidebarNav = document.querySelector<HTMLElement>('.sidebar-mobile');
		var sidebarToggle = document.querySelector<HTMLElement>('.sidebar-toggle');
		var navbarToggle = document.querySelector<HTMLElement>('.navbar-toggle');
		sidebarNav.classList.toggle("toggled");
		sidebarToggle.classList.toggle("toggled");
		navbarToggle.classList.toggle("toggled");
	}

	selectedItem() {
		this.dataService.setCustomerId(this.customerid);
		NavbarComponent.currentCustomer = this.customers.find(o => o.id === this.customerid).name;
		//console.log(NavbarComponent.currentCustomer);
		window.location.reload();
		//let productCategory = this.currentcomputer.customFields.find(o => o.name === 'ProductCategory').value;
	}
	
	logout() {
		this.session.clearAll();
		this.customers = null;
		this.router.navigateByUrl('login');
	}

}
