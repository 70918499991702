import { Injectable } from '@angular/core';
const TOKEN = 'TOKEN';
const REFRESHTOKEN = 'REFRESHTOKEN';    
const CUSTOMERS = 'CUSTOMERS';
const CID = 'CID';
const CREDENTIALS = 'CREDENTIALS';
const STATECONFIG = 'STATECONFIG';
const USERNAME = 'USERNAME';
const USERROLE = 'USERROLE'
const LASTREFRESH = 'LASTREFRESH';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    setCredentials(username: string, password: string) {
        sessionStorage.setItem(CREDENTIALS, btoa(username + ':' + password));
    }

    setToken(token: string): void {
        sessionStorage.setItem(TOKEN, token);
    }

    setUserName(username: string): void {
        sessionStorage.setItem("USERNAME", username);
    }

    getUserName() {
        return sessionStorage.getItem(USERNAME);
    }

    getToken() {
        return sessionStorage.getItem(TOKEN);
    }

    setRefreshToken(refreshToken: string): void {
        sessionStorage.setItem(REFRESHTOKEN, refreshToken);
    }

    getRefreshToken() {
        return sessionStorage.getItem(REFRESHTOKEN);
    }

    setCustomers(customers: any) {
        sessionStorage.removeItem(CUSTOMERS);
        sessionStorage.setItem(CUSTOMERS, JSON.stringify(customers));
    }

    getCustomers(): any {
        return JSON.parse(sessionStorage.getItem(CUSTOMERS));
    }

    clearAll(): void {
        sessionStorage.clear();
    }

    isLoggedIn() {
        return sessionStorage.getItem(TOKEN) != null;
    }

    setCustomerId(customerid: string): void {
        sessionStorage.setItem(CID, customerid);
    }

    getCustomerId() {
        return sessionStorage.getItem(CID);
    }

    getCredentials() {
        return sessionStorage.getItem(CREDENTIALS);
    }

    setCustomerStateConfig(stateConfig: any) {
        sessionStorage.setItem(STATECONFIG, JSON.stringify(stateConfig));
    }
    
    async getCustomerStateConfig() {
        return await Promise.resolve(sessionStorage.getItem(STATECONFIG));
    }

    setUserRole(role = 'default') {
        sessionStorage.setItem(USERROLE, role);
    }

    getUserRole() {
        return sessionStorage.getItem(USERROLE);
    }

    setLastRefresh() {
        sessionStorage.setItem(LASTREFRESH, new Date().toString());
    }

    getLastRefresh() {
        return sessionStorage.getItem(LASTREFRESH);
    }

 }
